import {
  SCOPE_IDENTITY_ID_TEMPLATES,
  SCOPE_ORG_ID_TEMPLATES,
} from 'new-components/OpAppScaffold/constants';

export const SUB_ORG_SCOPES = ['s-o:r', 's-o:w', 's-o-video:r', 's-o-video:w'];
export const SUB_ORG_FEATURE_CODES = ['subOrgs', 'subOrgsVideo'];

/**
 * @description Get the user's suborgs scopes when not referring to a specific org.
 *  EX: the org switcher for partner users
 * @returns {array} returns all the user's scopes in the parent org.
 */
export const getAnySubOrgScopes = ({
  tokenScopeList,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
}) => {
  const subOrgsScopes = new Set();

  tokenScopeList?.forEach((tokenScope) => {
    // Check if this org has the suborgs feature
    const hasSubOrg = tokenScope?.org?.packagePlans?.find((packagePlan) => {
      return packagePlan.package?.packageFeatures?.some((packageFeature) =>
        SUB_ORG_FEATURE_CODES.includes(packageFeature.feature?.code ?? ''),
      );
    });

    if (hasSubOrg) {
      (
        tokenScope?.scope?.filter((scope) => SUB_ORG_SCOPES.includes(scope)) ??
        []
      ).forEach((scope) => subOrgsScopes.add(scope));
    }
  });

  return [...subOrgsScopes] as string[];
};

/**
 * @description Get the user's applicable parent org scopes. Scopes are applicable
 * if the org has a parent, that parent has a subOrgs license, and the user has subOrg
 * related scopes in that org.
 * @returns {array} returns all the user's scopes in the parent org.
 */
export const getParentSubOrgScopes = ({
  tokenScopeList,
  parentOrgId,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
  parentOrgId?: number;
}) => {
  if (!parentOrgId) return [];

  // Get the parent org's TokenScope
  const parentOrgTokenScope = tokenScopeList?.find(
    (tokenScope) => tokenScope.org?.id === parentOrgId,
  );

  // Check if the parent has the suborgs feature using the shared function
  const hasSubOrg = hasSubOrgFeature({
    tokenScopeList,
    orgIdToCheck: parentOrgId,
  });

  // Only return sub-org scopes if the parent org has the sub-org feature
  return hasSubOrg
    ? (parentOrgTokenScope?.scope?.filter((scope) =>
        SUB_ORG_SCOPES.includes(scope),
      ) ?? [])
    : [];
};

/**
 * @description Get the user's current identity scopes.
 * @returns {array} the user's current identity scopes.
 */
export const getActiveIdentityScopes = ({
  tokenScopeList,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
}) => {
  if (!tokenScopeList) return []; // user has no scopes

  return tokenScopeList.find((tokenScope) => !tokenScope.org?.id)?.scope ?? [];
};

/**
 * @description Checks if an org has the subOrg feature enabled
 * @param tokenScopeList - list of user's token scopes
 * @param orgIdToCheck - the ID of the org to check
 * @returns {boolean} true if the org has the subOrg feature, false otherwise
 */
export const hasSubOrgFeature = ({
  tokenScopeList,
  orgIdToCheck,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
  orgIdToCheck?: number;
}) => {
  if (!tokenScopeList || !orgIdToCheck) return false;

  const orgTokenScope = tokenScopeList.find(
    (tokenScope) => tokenScope.org?.id === orgIdToCheck,
  );

  if (!orgTokenScope?.org?.packagePlans) return false;

  return orgTokenScope.org.packagePlans.some((packagePlan) => {
    if (!packagePlan.package?.packageFeatures) return false;

    return packagePlan.package.packageFeatures.some((packageFeature) => {
      if (!packageFeature.feature?.code) return false;

      return SUB_ORG_FEATURE_CODES.includes(packageFeature.feature.code);
    });
  });
};

/**
 * @description Get the user's current applicable scopes.
 * @param tokenScopeList - list of user's token scopes
 * @param orgId - org you want applicable scopes for - if no orgId
 *  get only identity level and partner scopes
 * @param parentOrgId - parent of org you want applicable scopes for
 * @returns {array} a combination of the User's scopes in the current org,
 * the parent org, and their identity level scopes
 */
export const getActiveScopes = ({
  tokenScopeList,
  orgId,
  parentOrgId,
}: {
  tokenScopeList: Api.Response['describeAccessToken']['tokenScopeList'];
  orgId?: number;
  parentOrgId?: number;
}) => {
  if (!tokenScopeList) return []; // user has no scopes

  // Get the user's identity scopes
  const identityScopes =
    tokenScopeList.find((tokenScope) => !tokenScope.org?.id)?.scope ?? [];

  // Get the user's current org's scopes
  const currentOrgScopes =
    tokenScopeList.find((tokenScope) => tokenScope.org?.id === orgId)?.scope ??
    [];

  // Check if the current org has the sub-org feature
  const currentOrgHasSubOrgFeature = hasSubOrgFeature({
    tokenScopeList,
    orgIdToCheck: orgId,
  });

  // If the current org doesn't have the sub-org feature, filter out all sub-org scopes
  const filteredCurrentOrgScopes = currentOrgHasSubOrgFeature
    ? currentOrgScopes
    : currentOrgScopes.filter((scope) => !SUB_ORG_SCOPES.includes(scope));

  // Check if parent org has sub org feature
  const parentOrgHasSubOrgFeature = hasSubOrgFeature({
    tokenScopeList,
    orgIdToCheck: parentOrgId,
  });

  // If the parent org has the sub-org feature, get the parent org's sub-org scopes
  const parentOrgSubOrgScopes = parentOrgHasSubOrgFeature
    ? (
        tokenScopeList.find((tokenScope) => tokenScope.org?.id === parentOrgId)
          ?.scope ?? []
      ).filter((scope) => SUB_ORG_SCOPES.includes(scope))
    : [];

  // Combine all scopes into a unique set
  return Array.from(
    new Set([
      ...identityScopes,
      ...filteredCurrentOrgScopes,
      ...parentOrgSubOrgScopes,
    ]),
  );
};

/**
 * @description inject orgId into org scopes
 * @returns {object} a copy of scopes where {orgId} is replaced with the org id
 */
export const injectOrgIdIntoOrgScopes = ({
  scopes,
  orgId,
}: {
  scopes: string[];
  orgId: number;
}) =>
  scopes.map((scope) =>
    SCOPE_ORG_ID_TEMPLATES.reduce(
      (result, template) => result.replace(template, String(orgId)),
      scope,
    ),
  );

/**
 * @description inject identityId into scopes
 * @returns {object} a copy of scopes where {identityId} is replaced with the identity id
 */
export const injectIdentityIdIntoScopes = ({
  scopes,
  identityId,
}: {
  scopes: string[];
  identityId: number;
}) =>
  scopes.map((scope) =>
    SCOPE_IDENTITY_ID_TEMPLATES.reduce(
      (result, template) => result.replace(template, String(identityId)),
      scope,
    ),
  );

/**
 * @description validates the user has proper scopes
 * @returns {boolean} true if the user has at least 1 allowed scope
 * and false if not
 */
export const validateScopes = ({
  activeScopes,
  rawAllowedScopes,
  orgId,
  identityId,
}: {
  activeScopes?: string[] | null;
  rawAllowedScopes: string[];
  orgId?: number;
  identityId?: number;
}) => {
  if (!activeScopes) return false;

  let allowedScopes = rawAllowedScopes;

  if (orgId) {
    allowedScopes = injectOrgIdIntoOrgScopes({
      scopes: rawAllowedScopes,
      orgId,
    });
  }

  if (identityId) {
    allowedScopes = injectIdentityIdIntoScopes({
      scopes: allowedScopes,
      identityId,
    });
  }

  return allowedScopes.some((requiredScope) =>
    activeScopes.includes(requiredScope),
  );
};
